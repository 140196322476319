<template>
<v-container fluid>
    <v-row justify="center" no-gutters>
    <v-col class="main--text mt-8" align="center">
      <v-img contain height="490" src="@/assets/certificate.jpeg"/>
    </v-col>
  </v-row>
  <br>
    <v-row justify="center" no-gutters>
        <v-col>
            <h1 class="white--text">Reviews</h1>
        </v-col>
    </v-row>
    <br/>

    <v-slide-group
      v-if="this.$vuetify.breakpoint.width > 550"
      v-model="model"
      active-class="secondary"
      class="white"
      show-arrows
    >
      <v-slide-item
        v-for="(item, index) in items"
        :key="index"
        v-slot="{ toggle }"
      >
        <v-card
          class="secondary mx-5 pb-4 my-4"
          width="500"
          elevation="0"
          @click="toggle"
        >
        <v-card-title class="primary--text"> 
            <v-icon size="x-large" class="primary--text mr-4">mdi-account-circle</v-icon> 
            <span class="mr-10">{{ item.title }}</span>
            <span v-if="!item.number">
                <v-icon
                    v-for="n in 5"
                    :key="n"
                    size="x-large" 
                    class="accent--text">{{ item.stars }}
                </v-icon>
            </span>
            <span v-if="item.number">
                <v-icon
                    v-for="n in 4"
                    :key="n"
                    size="x-large" 
                    class="accent--text">{{ item.stars }}
                </v-icon>
            </span>
        </v-card-title>
        <v-card-text> 
            "{{ item.text }}"
        </v-card-text>
        <p class="primary--text text-center"> {{ item.date }}</p>
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <v-row jutify="center" v-if="this.$vuetify.breakpoint.width < 550">
        <v-col>
            <v-card 
            v-for="(item, index) in items"
            :key="index"
            elevation="0"
            class="secondary white--text"
            >
                <v-card-title class="primary--text"> 
                    <v-icon size="x-large" class="primary--text mr-4">mdi-account-circle</v-icon> 
                    <span class="mr-5">{{ item.title }}</span>
                    <span v-if="!item.number">
                        <v-icon
                            v-for="n in 5"
                            :key="n"
                            size="x-large" 
                            class="accent--text">{{ item.stars }}
                        </v-icon>
                    </span>
                    <span v-if="item.number">
                        <v-icon
                            v-for="n in 4"
                            :key="n"
                            size="x-large" 
                            class="accent--text">{{ item.stars }}
                        </v-icon>
                    </span>
                </v-card-title>
                <v-card-text> 
                    "{{ item.text }} "
                </v-card-text>
                <p class="pa-2 primary--text text-end"> {{ item.date }}</p>
            </v-card>

        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: 'Reviews',
    data (){
        return{
            model: null,
            items: [
                {
                    title: 'Chris & Angela Meister',
                    text: "Thank you for this most wonderful campsite. This is the true Baviaans feeling! And also many thanks for your attention to detail. Everything is so clean and tidy, but still rustic - we loved it!",
                    stars: 'mdi-star',
                    date: 'Lekkeslaap review - Dec 2024'
                },
                {
                    title: 'Zibi F',
                    text: "To experience wild camping then this is the place. Intimate and cosy. Fantastic rustic camp",
                    stars: 'mdi-star',
                    date: 'Lekkeslaap review - Apr 2024'
                },
                {
                    title: 'Eddie F',
                    text: "This campsite is beautifully kept and clean. An honesty box is available to make your payment. We arrived one evening and there was no other campers. We had this lovely place to ourselves and made our honest payment. 50% of revenue goes towards the local community. Thank you Laaste Kamp for a well kept site in the middle of nowhere.",
                    stars: 'mdi-star',
                    date: 'Google review - Jul 2023'
                },{
                    title: 'Carina van Zyl',
                    text: "Beautiful, well planned campsite with a wonderful and much-used plunge pool. The showers in the rocks are just divine",
                    stars: 'mdi-star',
                    date: 'Google review - May 2023'
                },{
                    title: 'Birgitta',
                    text: "Ultimate bush camp destination\nThis is a remote bush camp in the greater Baviaanskloof with no solar option, nor hot water or other faciliaties. This is design for those that arrives with own tent, prepared for cold showers and dark evenings if you do not have your own lights.\nThis is a bush camp and what we expected. Disliked. It should offer solar powered for light as it gets very dark, and the camp is not really fenced off and you hear the wildlife so a bit daunting at night when you step out for a toilet visit at night. You feel the baboons and other animals watching you. It should also have signage with information on trails you can take, if any wild animals we must be prepared to meet, and that's it.",
                    stars: 'mdi-star',
                    date: 'Bookings review - Apr 2023'
                },{
                    title: 'Anonymous',
                    text: "Great stop over for adventure seekers. Perfectly located just outside the nature reserve gate.\nGreat location, well kept.Disliked\nNo hot water, cold shower was very refreshing tough so not a big deal. Owners were supposed to leave us a few bags of firewood which they seem to have forgotten, unless we just didn’t see it.",
                    stars: 'mdi-star',
                    date: 'Bookings review - Mar 2023'
                },{
                    title: 'Laura G',
                    text: "A little slice of heaven! My husband, dog and I spent 4 nights here in December 2022. It was utter bliss. The camp is beautifully maintained with lush green grass. Two showers with ample space in each have been built into the rock face. The water is even warm in the afternoon! We choose a shady spot next a stone braai place. There were also free-standing Braais and a tape near each site which is brilliant! Toilets are the other side of the camp to the showers. Clean and well maintained with a large sink outside for washing dishes etc. The cherry on top for us was the small natural plunge pool. We spent most of our time in it! The camp has ample water so they empty and clean the pool regularly. There’s also a lager stone braai near the pool surrounded by lots of grass and trees. Can’t recommend the camp highly enough",
                    stars: 'mdi-star',
                    date: 'Google review - Feb 2023'
                },{
                    title: 'Yvette J',
                    text: "Ongelooflike rustige plek as jy wil ontspan en afskakel! Wonderlike kampplek om in die natuur deur te bring. Glad nie 'n slegte pad om te ry van Willowmore af nie! Slegs 1 km vanaf die reservaat se hek. Rustig en stil, heerlike sementswembadjie om in af te koel! Netjiese badkamergeriewe! Geen elektrisiteit of selfoonontvangs nie, net wat mens nodig het om te ontspan!",
                    stars: 'mdi-star',
                    date: 'Lekkeslaap review - Dec 2022'
                }
            ]
        }
    }

}
</script>

<style>

</style>